<template>
  <v-container>
    <v-row>
      <v-col class="result">
        <v-card class="failure-card">
          <v-card-title>{{ $t('order-failure.title') }}</v-card-title>
          <v-card-text class="result-icon"><v-icon color="error">mdi-close-circle-outline</v-icon></v-card-text>
          <v-card-text
            v-if="paymentError"
            class="main-text"
            v-html="$t('order-failure.main-text-payment')"
          />
          <v-card-text
            v-else
            class="main-text"
            v-html="$t('order-failure.main-text-generic')"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Failure',
    props: {
      oid: {
        type: [Number, String],
        default: -1,
      },
      paymentError: {
        type: Boolean,
        default: false,
      },
    },
    created () {
      this.$store.commit('orders/ERROR_PAGE_DECREASE_COUNTER')
    },
  }
</script>

<style lang="scss" scoped>
.result {
  .failure-card {
    border-radius: 0;
    margin: 0 ;
    max-width: 700px;
    .v-card__title{
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      background-color: #FF5252; //theme error color
    }
    .v-card__text {
      color: $goldairBlue;
      font-weight: 300;
      margin: 0 auto;
      text-align: center;
      line-height: 1.65em !important;
      &.result-icon{
        padding-top: 60px;
        .v-icon{
          font-size: 68px !important;
        }
      }
      &.main-text{
        font-size: 23px;
        padding-bottom: 60px;
      }
    }
  }
}
</style>
